import request from './ajax'
// 进货路由模块
const purchasesUrl = '/api/purchases/'
// 获取进货商品列表
export const getPurchasesList = (data) => request(purchasesUrl + 'goods', 'post', { ...data })
// 获取我的店铺
export const getMyShop = (data) => request(purchasesUrl + 'my_shop', 'post', { ...data })
// 创建进货订单
export const createPurchasesOrder = (data) => request(purchasesUrl + 'create', 'post', { ...data })

