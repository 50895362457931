<template>
  <div>
    <NavBar class="nav-bar">
      <div slot="title">确认订单</div>
    </NavBar>
    <div class="layout-page">
      <!-- 地址 -->
      <div class="address-layout" @click="setAddress">
        <van-icon name="location-o" size="20" color="#9B9B9B" />
        <div class="shop-info">
          <div class="contact">
            <span>{{ addressInfo.consignee }}</span>
            <span class="mobile">{{ addressInfo.phone }}</span>
          </div>
          <div class="address">
            {{ addressInfo.city_names }} {{ addressInfo.address }}
          </div>
        </div>
        <van-icon name="arrow" size="20" color="#9B9B9B" class="icon-right" />
      </div>
      <!-- 选择门店地址 -->
      <div class="shop-address-card" @click="selectShop">
        <!-- 未选择门店的时候 -->
        <div class="title-row" v-if="shopInfo.id * 1 <= 0">
          <div class="icon-img-box">
            <img src="~@/assets/common/store_positioning_icon.png" />
          </div>
          <span>请选择门店</span>
          <van-icon name="arrow" class="icon-img" size="20" color="#9B9B9B" />
        </div>
        <!-- 已选择门店的时候 -->
        <div class="shop-info" v-else>
          <div class="icon-img-box">
            <img src="~@/assets/common/store_positioning_icon.png" />
          </div>
          <div>
            <div class="shop-mobile">
              <span>{{ shopInfo.shop_name }}</span>
              <span>{{ shopInfo.shop_phone }}</span>
            </div>
            <div class="address">
              {{ shopInfo.city_name }} {{ shopInfo.address }}
            </div>
          </div>
          <van-icon name="arrow" class="icon-img" size="20" color="#9B9B9B" />
        </div>
      </div>
      <!-- 商品卡片 -->
      <div class="card-layout">
        <div v-for="(shopGoods, s_index) in goodsList" :key="s_index">
          <div class="card-header">
            <!-- <van-icon name="shop-o" color="#9b9b9b" /> -->
            <img src="~@/assets/common/store_icon@2x.png" class="shop-img" />
            <div class="title">供应商：{{ shopGoods.shop_name }}</div>
          </div>
          <!-- 订单详情 -->
          <div
            class="order-details"
            v-for="(goods, g_index) in shopGoods.goods_list"
            :key="g_index"
          >
            <div class="img-box">
              <img class="commodity-img" :src="goods.goods_img" alt="" />
            </div>
            <div class="commodity-info">
              <div class="title-info">
                <div>{{ goods.goods_name }}</div>
                <span>￥{{ goods.spec_price }}</span>
              </div>
              <div class="specification">
                <div class="specification-info">
                  <div class="measure">{{ goods.item_name }}</div>
                </div>
              </div>
              <div>
                <span class="tag" v-if="goods.freight_money * 1 <= 0">
                  免运费
                </span>
                <span class="tag" v-else>
                  运费：{{ twoNum(goods.freight_money) }}
                </span>
              </div>
            </div>
            <div class="commodity-right">
              <!-- <span class="beans-num"></span> -->
              <div class="number-of">x{{ goods.number }}</div>
            </div>
          </div>
          <div class="operation">
            <van-cell-group :border="false">
              <van-field
                v-model="value"
                label="配送方式"
                readonly
                placeholder="快递配送"
              />
            </van-cell-group>
            <van-field
              v-model="message[s_index].msg"
              rows="2"
              autosize
              label="留言"
              type="text"
              placeholder="建议留言前先与商家沟通"
              show-word-limit
            />
          </div>
        </div>
      </div>
      <div class="beans-price">
        <div class="price layout">
          <span>商品总价</span>
          <span>￥{{ twoNum(totalInfo.total_price) }}</span>
        </div>
        <div class="send-price layout">
          <span>配送费用</span>
          <span class="color">￥{{ twoNum(totalInfo.total_postage) }}</span>
        </div>
        <div class="price layout">
          <div>
            <span>进货金</span>
            <span style="color: #ed301d">
              （可用进货金￥{{ twoNum(moneyInfo.purchase_money) }}）
            </span>
          </div>
          <div
            style="display: flex; align-items: center; color: #fd6d1f"
            @click="stockGold"
          >
            <span>-￥{{ twoNum(purchaseMoney) }}</span>
            <van-icon name="arrow" class="arrow" />
          </div>
        </div>
        <div class="price layout">
          <span>合计</span>
          <span>￥{{ twoNum(totalMoney) }}</span>
        </div>
      </div>
      <div class="bottom-button">
        <div class="price-info">
          <span style="color: #aaaaaa">共1件，</span>
          <span>合计：</span>
          <span style="color: #ed301d">
            <span>￥</span>
            <span class="integer">{{ toInteger(totalMoney) }}</span>
            <span>{{ twoFloating(totalMoney) }}</span>
          </span>
        </div>
        <div class="button-box">
          <van-button
            round
            color="linear-gradient(to right, #ff6034, #ee0a24)"
            @click="createOrder"
          >
            提交订单
          </van-button>
        </div>
      </div>
    </div>
    <!-- 进货金 -->
    <van-action-sheet v-model="isShow" title="使用进货金">
      <div class="content">
        <div class="layout at-present">
          <span>当前进货金</span>
          <span>￥{{ twoNum(moneyInfo.purchase_money) }}</span>
        </div>
        <!-- <div class="layout xiao-fei-jin">
          <span>本次最多可用进货金</span>
          <span
            v-if="
              moneyInfo.purchase_money * 1 <
              totalInfo.total_price * 1 + totalInfo.total_postage * 1
            "
            >￥{{ twoNum(moneyInfo.purchase_money) }}</span
          >
          <span v-else
            >￥{{
              twoNum(totalInfo.total_price * 1 + totalInfo.total_postage * 1)
            }}</span
          >
        </div> -->
        <div class="layout at-present">使用进货金</div>
        <div class="input-box">
          ￥<input type="number" class="input" v-model="purchaseMoney" />
        </div>
        <van-button
          block
          round
          color="linear-gradient(to right, #F25757 , #ED301D)"
          class="button-position"
          @click="setShow"
        >
          确认使用
        </van-button>
      </div>
    </van-action-sheet>
    <!-- 地址选择弹出层 -->
    <AllShop v-if="showOverlay" @setMyShop="setMyShop"></AllShop>
    <!--收货地址修改-->
    <AddressList
      v-if="showAddress"
      @setAddressInfo="setAddressInfo"
      :orderType="1"
      :isRadio="true"
      class="address-list"
      @closePage="closePage"
    ></AddressList>
    <!--订单支付-->
    <PayShade
      v-if="isPay"
      :moneyInfo="moneyInfo"
      :orderId="orderId"
      :showPopup="isPay"
      @closeOverlay="closeOverlay"
    ></PayShade>
  </div>
</template>

<script>
import AllShop from "./components/all-shop";
import NavBar from "@/layout/nav-bar/nav-bar";
import { setOrderPay } from "@/api/order";
import { Toast } from "vant";
import { getAddressInfo } from "@/api/member";
import { getShoppingGoods, getTotalPrice } from "@/api/shopping";
import { getMyShop, getMyShopMoneyInfo } from "@/api/shop";
import AddressList from "@/pages/my/address-list/address-list";
import { createPurchasesOrder } from "@/api/purchases";
import PayShade from "@/components/pay-shade/pay-shade";
import tools from "@/common/js/tools";

export default {
  name: "commit-order",
  components: {
    NavBar,
    AllShop,
    AddressList,
    PayShade,
  },
  data() {
    return {
      orderType:
        this.$route.query.orderType === undefined
          ? "1"
          : this.$route.query.orderType,
      shoppingIds:
        this.$route.query.shoppingIds === undefined
          ? "0"
          : this.$route.query.shoppingIds,
      message: [],
      numberOf: 0,
      goodsList: [],
      totalInfo: { total_price: 0, total_postage: 0, total_expense: 0 },
      totalMoney: 0,
      purchaseMoney: "",
      addressInfo: { id: 0, province: 0, city: 0, area: 0 },
      shopInfo: { id: 0, shop_name: "" },
      moneyInfo: { purchase_money: 0, shop_money: 0 },
      showAddress: false,
      isPay: false,
      payType: 1,
      value: "",
      field: "",
      showOverlay: false,
      isShow: false, // 弹出层
    };
  },
  watch: {
    purchaseMoney: function () {
      if (isNaN(this.purchaseMoney)) {
        this.purchaseMoney = "";
      }
      if (this.purchaseMoney != "") {
        if (this.purchaseMoney < 0) {
          this.purchaseMoney = "";
        } else {
          let total_price =
            this.totalInfo.total_price * 1 + this.totalInfo.total_postage * 1;
          if (this.purchaseMoney * 1 >= total_price * 1) {
            this.purchaseMoney = total_price;
            this.payType = 0;
          } else {
            this.payType = 1;
          }
          if (this.purchaseMoney * 1 > this.moneyInfo.purchase_money * 1) {
            this.purchaseMoney = this.moneyInfo.purchase_money * 1;
          }
          // this.purchaseMoney=(this.purchaseMoney*1).toFixed(2);
          this.setTotalMoney();
          // this.payMoney=this.payMoney.toFixed(2);
        }
      }
    },
  },
  mounted() {
    this.getShoppingGoods();
    this.getAddressInfo();
    this.getMyShop();
  },
  methods: {
    closePage() {
      //关闭地址显示
      this.showAddress = false;
    },
    closeOverlay() {
      // 关闭遮罩层自定义事件方法
      this.isPay = false;
      if (this.orderType * 1 === 2) {
        this.$router.replace({
          path: "/purchases-order",
          query: {
            orderType: 2,
          },
        });
      }
    },

    async getShoppingGoods() {
      //获取商品信息
      const ret = await getShoppingGoods({ shopping_ids: this.shoppingIds });
      if (ret.code * 1 === 1) {
        for (let i = 0; i < ret.data.length; ++i) {
          this.message.push({ msg: "" });
        }
        this.goodsList = ret.data;
      }
      this.getTotalPrice();
    },
    async getTotalPrice() {
      //获取订单总价
      const ret = await getTotalPrice({
        shopping_ids: this.shoppingIds,
        province_id: this.addressInfo.province,
        city_id: this.addressInfo.city,
      });
      if (ret.code * 1 === 1) {
        this.totalInfo = ret.data;

        this.setTotalMoney();
      }
    },
    setTotalMoney() {
      this.totalMoney =
        this.totalInfo.total_price +
        this.totalInfo.total_postage -
        this.purchaseMoney;
      //计算订单邮费
      this.goodsList.forEach((shop, shopKey) => {
        if (this.totalInfo.shop_list[shop.shop_id] !== undefined) {
          shop.goods_list.forEach((goods, goodsKey) => {
            if (
              this.totalInfo.shop_list[shop.shop_id].goods_postage[
                goods.spec_id
              ] !== undefined
            ) {
              goods.freight_money =
                this.totalInfo.shop_list[shop.shop_id].goods_postage[
                  goods.spec_id
                ];
            }
          });
        }
      });
    },
    setAddress() {
      //选择收货地址
      localStorage.setItem("ADDRESS_ID", this.addressInfo.id);
      this.showAddress = true;
    },
    setAddressInfo(Ainfo) {
      // 设置收货地址
      // this.addressInfo = Ainfo;
      this.getAddressInfo(Ainfo.id);
      this.showAddress = false;
      this.getTotalPrice();
    },
    delPass() {
      //密码清除
      let passNum = this.passStr.length;
      if (passNum > 1) {
        this.passStr = this.passStr.substr(0, passNum - 1);
      } else {
        this.passStr = "";
      }
    },
    setPass(key) {
      //设置密码
      if (this.passStr.length < 6) {
        this.passStr += key;
      }
      if (this.passStr.length == 6) {
        //调用支付
        this.setOrderPay();
      }
    },
    goToPaySuccess() {
      this.$router.push({
        name: "pay-success",
        query: { orderId: this.orderId, payMoney: this.payMoney },
      });
    },
    async setOrderPay() {
      //订单支付
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      const ret = await setOrderPay({
        balance_money: this.payMoney,
        order_id: this.orderId,
        pay_pass: this.passStr,
        pay_type: this.payType,
      });
      if (ret.code * 1 == 1) {
        if (ret.data.pay_status) {
          //跳转到支付成功页面
          this.goToPaySuccess();
        } else {
          //调用对应模块的支付
        }
      }
      this.isAjax = false;
    },
    async createOrder() {
      // 创建订单
      this.showPopup = true;
      if (this.addressInfo.id * 1 <= 0) {
        Toast.fail("请选择收货地址");
        return false;
      }
      if (this.shopInfo.id * 1 <= 0) {
        Toast.fail("请选择进货店铺");
        return false;
      }
      if (this.shoppingIds == "0") {
        Toast.fail("商品信息异常");
        return false;
      }
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      const ret = await createPurchasesOrder({
        shopping_ids: this.shoppingIds,
        address_id: this.addressInfo.id,
        shop_id: this.shopInfo.id,
        purchase_money: this.purchaseMoney,
        note: this.message,
      });
      if (ret.code * 1 == 1) {
        this.orderId = ret.data.order_id;
        if (this.payType * 1 == 0) {
          this.goToPaySuccess();
        } else {
          this.getOrderPayInfo();
        }
      }
      this.isAjax = false;
    },
    async getOrderPayInfo() {
      //获取订单支付信息
      this.isPay = true;
      // const ret = await getOrderPayInfo({order_id: this.orderId});
      // if (ret.code * 1 === 1) {
      //   this.payInfo = ret.data;
      //   this.isPay = true;
      // }
    },
    async getMoneyInfo() {
      //获取会员资金信息
      const ret = await getMyShopMoneyInfo();
      if (ret.code * 1 === 1) {
        this.moneyInfo = ret.data;
      }
    },
    twoNum(num) {
      if (num === "") num = 0;
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    setExpenseMoney(expenseMoney) {
      this.expenseMoney = expenseMoney;
    },
    onFocus() {
      if (this.isFocus) {
        this.showKeyboard = true;
        this.show = true;
      }
    },
    async getAddressInfo() {
      const ret = await getAddressInfo();
      if (ret.code * 1 === 1) {
        this.addressInfo = ret.data;
        this.$emit("getAddressId", this.addressInfo);
      }
    },
    async getMyShop() {
      const ret = await getMyShop();
      if (ret.code * 1 === 1) {
        this.shopInfo = ret.data;
        this.moneyInfo = ret.data;
      }
    },

    selectShop() {
      //显示门店列表
      this.showOverlay = true;
    },
    setMyShop(shopInfo) {
      // 选择门店
      this.showOverlay = false;
      if (shopInfo) {
        this.shopInfo = shopInfo;
        this.getMyShopMoneyInfo();
      }
    },
    async getMyShopMoneyInfo() {
      //获取店铺进货金信息
      const ret = await getMyShopMoneyInfo({ shop_id: this.shopInfo.id });
      if (ret.code * 1 == 1) {
        this.moneyInfo = ret.data;
      }
    },
    stockGold() {
      this.isShow = true;
    },
    setShow() {
      this.isShow = false;
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // price = Math.floor(num * 100) / 100;
      // price = String(price).split(".")[1];
      // if (price !== undefined && price.length === 1) {
      //   price = `.${price}0`;
      // } else {
      //   price === undefined ? (price = ".00") : (price = `.${price}`);
      // }
      //
      // return price;
    },
  },
};
</script>

<style lang="less" scoped>
.layout-page {
  padding: 50px 0 74px 0;
}

.address-list {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}

.nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  border-bottom: 1px solid #ededed;
  background-color: #fff;
}

// 地址
.address-layout {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 12px;
  background-color: #fff;
  border-radius: 0 0px 16px 16px;
  font-size: 16px;

  .icon-right {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  .shop-info {
    padding: 0 0 0 10px;
    width: 80%;

    .contact {
      font-weight: 500;
      font-size: 17px;

      .mobile {
        color: #6f6f6f;
        font-weight: 400;
        padding-left: 10px;
      }
    }

    .address {
      margin-top: 6px;
      font-size: 16px;
      color: #6f6f6f;
    }
  }
}

// 选择门店地址
.shop-address-card {
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  padding: 12px;
  margin-top: 12px;
  font-size: 16px;

  .icon-img-box {
    display: flex;
    align-items: center;
    width: 20px;
    padding-right: 10px;

    img {
      width: 100%;
      display: block;
    }
  }

  .icon-img {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  .title-row {
    display: flex;
    align-items: center;
  }

  .shop-info {
    display: flex;

    .shop-mobile {
      font-weight: 500;
      font-size: 17px;

      span {
        padding-right: 10px;
      }
    }

    .address {
      width: 90%;
      font-size: 16px;
      padding-top: 10px;
      color: #6f6f6f;
    }
  }
}

// 商品详情
.card-layout {
  padding: 12px;
  padding-bottom: 0;
  margin-top: 12px;
  border-radius: 15px;
  background-color: #fff;
}

.card-header {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  .shop-img {
    display: block;
    width: 21px;
    height: 21px;
  }
  .title {
    margin-left: 4px;
    font-size: 17px;
  }
}

/deep/ .operation {
  .van-cell {
    padding: 6px 0;

    .van-field__label {
      font-size: 17px;
      color: #000;
    }
  }
}

// 商品详情
.order-details {
  position: relative;
  padding: 14px 0;
  display: flex;
  justify-content: start;
  font-size: 16px;

  .img-box {
    width: 110px;
    flex-shrink: 0;

    .commodity-img {
      display: block;
      width: 110px;
      height: 110px;
      border-radius: 6px;
    }
  }

  .commodity-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;

    .title-info {
      display: flex;
      justify-content: space-between;

      div {
        width: 141px;
        display: -webkit-box; /**对象作为伸缩盒子模型展示**/
        -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
        -webkit-line-clamp: 2; /**显示的行数**/
        overflow: hidden; /**隐藏超出的内容**/
        word-break: break-all; /** 英语字母设定问题，它不会换行在代码中加入强制换行 可以解决这个问题**/
      }

      span {
        &:nth-child(2) {
          color: #000;
          font-size: 17px;
          font-weight: 500;
        }
      }
    }

    .specification {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #aaaaaa;

      .specification-info {
        display: flex;
        flex-direction: column;
        justify-content: start;

        .measure {
          height: 26px;
          background: #f5f5f5;
          opacity: 1;
          border-radius: 6px;
          color: #6f6f6f;
          font-size: 14px;
          text-align: center;
          line-height: 26px;
          padding: 0 10px;
        }
      }
    }
  }

  .commodity-right {
    position: absolute;
    right: 0;
    top: 14px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .number-of {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      color: #aaaaaa;
    }
  }
}

.tag {
  border: 1px solid #da432e;
  opacity: 1;
  border-radius: 6px;
  color: #da432e;
  text-align: center;
  line-height: 21px;
  padding: 3px 8px;
  font-size: 12px;
}

// 公益豆的价格
.beans-price {
  margin-top: 12px;
  padding: 12px;
  background-color: #fff;
  border-radius: 16px;
  font-size: 16px;

  .price {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 4px 0;

    .arrow {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  .layout {
    padding-right: 18px;
  }
}

// 底部按钮
.bottom-button {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 63px;
  border-top: 1px solid rgb(243, 243, 243);
  background-color: #fff;
  padding: 0 13px;

  .price-info {
    font-size: 14px;

    .integer {
      font-size: 18px;
    }
  }

  .price {
    font-size: 16px;
  }

  .van-button--normal {
    padding: 0 30px;
  }

  .button-box {
    padding-left: 16px;
  }
}
.van-button {
  width: 116px;
  height: 47px;
  padding: 0 !important;
}
.van-button__text {
  font-size: 17px;
}
// 进货金弹出层
.content {
  padding: 16px;
}

.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 17px;
}

.xiao-fei-jin {
  margin: 12px 0;
}

.input {
  width: 78px;
  height: 28px;
}

.content[data-v-36830e4c] {
  padding: 16px 16px 0px 16px;
}

.button-position {
  margin: 144px 0 10px 0;
}

.van-action-sheet__header {
  font-size: 19px;
}

.at-present {
  margin-bottom: 33px;
}

.input-box {
  font-size: 18px;

  input {
    margin-left: 10px;
    padding-bottom: 11px;
    border: none;
    font-size: 35px;
    width: 80%;
    border-bottom: 1px solid #e4e4e4;
  }
}
</style>
