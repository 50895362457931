<template>
  <div class="block">
    <Search class="search-layout">
      <div slot="left" class="search-left">
        <van-icon name="arrow-left" @click="close" @click.stop size="18" />
      </div>
      <div slot="right" class="search-right">
        <van-button type="danger" round block>搜索</van-button>
      </div>
    </Search>
    <div class="layout-page">
      <div class="site-card" v-for="(item, index) in shopList" :key="index">
        <div class="shop-distance">
          <span class="shop-name">{{ item.shop_name }}</span>
          <!-- <span>0.1km</span> -->
        </div>
        <div class="select-site">
          <van-radio-group v-model="radio" checked-color="#ED301D">
            <!-- 根据索引选择单选框，后期改为ID -->
            <van-radio :name="index" label-position="left">
              {{ item.city_name }} {{ item.address }}
            </van-radio>
          </van-radio-group>
        </div>
        <!-- <div class="beans" style="color: #aaaaaa">可用进货金:￥{{ 100 }}</div> -->
      </div>
    </div>
    <div class="button-box">
      <van-button block round type="danger" @click="setShopInfo(radio)">
        确定
      </van-button>
    </div>
  </div>
</template>

<script>
import Search from "@/components/search/search";
import { getAddressInfo } from "@/api/member";
import { getMyShop } from "@/api/purchases";
export default {
  name: "all-shop",
  components: {
    Search,
  },
  data() {
    return {
      radio: 0,
      shopList: [],
    };
  },
  mounted() {
    this.geMytShopList();
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.fun, false); //false阻止默认事件
    }
  },
  methods: {
    fun() {
      this.$emit("setMyShop");
      try {
        this.window.removeEventListener("popstate", this.fun, false);
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit("setMyShop");
    },
    async geMytShopList() {
      const ret = await getMyShop();
      if (ret.code * 1 === 1) {
        this.shopList = ret.data;
        console.log(ret.data);
      }
    },
    setShopInfo(key) {
      this.$emit("setMyShop", this.shopList[key]);
    },
  },
};
</script>

<style lang="less" scoped>
// vant 组件样式
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f9f9f9;
}
.van-radio {
  display: flex;
  justify-content: space-between;
}
/deep/.van-radio__label {
  font-size: 16px;
  color: #6f6f6f;
}
// 自定义内容样式
.search-layout {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 8000;
  border-bottom: 1px solid rgb(245, 245, 245);
}
.layout-page {
  min-height: 100vh;
  padding: 76px 12px;
  box-sizing: border-box;
}
.site-card {
  padding: 12px;
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 12px;
  .beans {
    font-size: 16px;
    color: #aaaaaa;
  }
  .shop-distance {
    font-size: 17px;
    display: flex;
    justify-content: space-between;
  }
  .select-site {
    padding: 10px 0;
    color: #6f6f6f;
  }
}
.button-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 14px;
  background-color: #fff;
  border-top: 1px solid rgb(245, 245, 245);
}
</style>
