<template>
  <div :class="{ 'search-layout': FiexdSearch }">
    <!-- <van-search
      v-model="value"
      show-action
      shape="round"
      background="#fff"
      placeholder="请输入搜索关键词"
    >
      <template #action>
        <slot name="right"></slot>
      </template>
      <template #left>
        <slot name="left"></slot>
      </template>
    </van-search> -->
    <!-- ---------------------------------------------------------------------------------------------- -->
    <!-- 如果使用本组件插槽，需要左右各定义类名search-left和search-right
    如果没有使用，需要外层套上盒子，给予边距 -->
    <div class="serach-layout">
      <slot name="left"></slot>
      <div class="search-box">
        <van-icon name="search" size="18" />
        <input
          type="text"
          v-model="value"
          class="input"
          placeholder="请输入关键词"
        />
      </div>
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    FiexdSearch: {
      tyep: Boolean,
      default: false,
    },
    selectStr: {
      tyep: String,
      default: "",
    },
  },
  data() {
    return {
      value: this.selectStr,
    };
  },
  watch: {
    value: function () {
      this.$emit("setSelectStr", this.value);
    },
  },
};
</script>

<style lang="less" scoped>
// vant搜索组件样式修改
// .van-search__content {
//   border: 1px solid #fa0336;
//   box-sizing: border-box;
//   background-color: #fff;
// }
// .search-right {
//   display: flex;
//   align-items: center;
// }
// .search-left {
//   display: flex;
//   align-items: center;
//   padding: 0 12px;
// }
// .van-search {
//   padding: 10px 0;
// }
// .van-button--normal {
//   padding: 0;
// }

// 自定义样式
.serach-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  height: 58px;
  box-sizing: border-box;
  .search-box {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    padding-left: 10px;
    border: 1px solid #fa0336;
    border-radius: 999px;
    box-sizing: border-box;
    .input {
      width: 80%;
      height: 50%;
      border: none;
      outline: none;
      font-size: 16px;
    }
  }
  .search-right {
    padding: 0 10px;
  }
  .search-left {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  // 如果是vant按钮
  .van-button--normal {
    padding: 0;
  }
  .van-button {
    width: 58px !important;
    height: 36px;
    font-size: 18px;
  }
}
</style>
